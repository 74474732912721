import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Headers from './header';
import Footers from './footer';
import { Redirect } from 'react-router';

class otpPref extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShowCapture = this.handleShowCapture.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);

        const LoginId = this.props.location.state.id;
        const LoginMobile = this.props.location.state.mobile;
        const LoginEmail = this.props.location.state.email;

        this.state = {
            redirect: false,
            Id: this.props.location.state.id,
            Mobile: this.props.location.state.mobile,
            Email: this.props.location.state.email,
            Mode: "Mobile",
            enabled: true
        };
    }
    onChangeValue(event) {
        this.setState({ enabled: false });
        // console.log(event.target.value);
        this.setState({ Mode: event.target.value });
    }

    handleShowCapture() {
        this.setState({ redirect: true });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to={{
                pathname: "/otpCapt",
                state: {
                    id: this.state.Id,
                    mobile: this.state.Mobile,
                    email: this.state.Email,
                    mode: this.state.Mode
                }
            }} />;
        }
        return (
            <div>
                <Headers></Headers>

                <div className="text-center">
                    <h3 style={{ margin: '50px 0px 0px 0px' }}>Select the preferred OTP channel</h3>

                    <br />
                    <Container>
                        <Row>
                            <Col></Col>
                            <Col xs={6}>
                                <div onChange={this.onChangeValue}>
                                    <input type="radio" value="Email" name="otppref" /> Email <span>  </span>
                                    <input type="radio" value="Mobile" name="otppref" /> Mobile
                                </div>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col></Col>
                            <Col xs={6}>
                                <p />
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col></Col>
                            <Col xs={6}>
                                <Button variant="secondary" disabled={this.state.enabled} onClick={this.handleShowCapture} block>Send</Button>{' '}
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>
                    <br />
                </div>
                <Footers></Footers>
            </div>
        );
    }
}

export default otpPref;