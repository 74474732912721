import React, { Component } from 'react';
import { Redirect } from 'react-router';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import profileICO from "../profile.png";
import Headers from './header';
import Footers from './footer';
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import "./logon.css";
import axios from "axios";
import Visitor from './visitor';


const config = require('../config.json');

class logonComp extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            show: false,
            directTo: "/otpPref",
            newVisitor: {
                "id": "",
                "firstname": "",
                "lastname": "",
                "company": "",
                "email": "",
                "laptop": "",
                "laptopserial": "",
                "mobile": "",
                "vehicle": "",
                "image": ""
            },
            visitors: [],
            showVisitorDontExist: "none",
            showVisitorExist: "none"
        };
    }

    handleChange = e => {
        this.setState({ newVisitor: { ...this.state.newVisitor, "id": e.target.value } })
        this.form.validateFields(e.target);
    }

    contactSubmit = e => {
        e.preventDefault();

        this.form.validateFields();

        if (!this.form.isValid()) {
            console.log('form is invalid: do not submit');
        } else {
            console.log('form is valid: submit');
            this.idExists().then((doexist) => {
                if (doexist) {
                    this.setState({ directTo: "/otpPref" }, function () {
                        this.setState({ directTo: "/otpPref" });
                        this.setState({ redirect: true });
                    });
                } else {
                    this.setState({ showVisitorDontExist: "block" });
                    this.setState({ showVisitorExist: "none" });
                }
            });
        }
    }

    handleRegister = e => {
        e.preventDefault();
        this.form.validateFields();
        if (!this.form.isValid()) {
            console.log('form is invalid: do not submit');
        } else {
            this.fetchVisitorRegister();
        }
    }

    idExists = () => {
        return new Promise((resolve, reject) => {
            axios.post(`${config.api.backendUrl}/exists/`, {
                "identifier": this.state.newVisitor.id,
            },
                {
                    "headers": { 'Content-Type': 'application/json', "x-api-key": `${config.api.xapikey}` }
                }
            )
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    if (err.response.status === 404) {
                        resolve(false);
                    } else {
                        reject(err);
                    }
                });
        });
    }

    fetchVisitor = async () => {
        // add call to AWS API Gateway to fetch products here
        // then set them in state
        try {
            const params = {
                "id": this.state.newVisitor.id,
                "session": localStorage.getItem('session')
            };
            const res = await axios.post(`${config.api.backendUrl}/visitorsingle`, params, {
                "headers": {
                    "x-api-key": `${config.api.xapikey}`
                }
            });

            this.setState({ visitors: res.data }, function () {

            });

            if (this.state.visitors.length == 0) {
                this.setState({ showVisitorDontExist: "block" });
                this.setState({ showVisitorExist: "none" });
            } else {
                if ((res.data[0].email === '') || (res.data[0].email == undefined)) {
                    this.setState({ newVisitor: { ...this.state.newVisitor, "email": " @ " } })
                } else {
                    this.setState({ newVisitor: { ...this.state.newVisitor, "email": res.data[0].email } })
                }
                this.setState({ newVisitor: { ...this.state.newVisitor, "mobile": res.data[0].mobile } })
                this.setState({ directTo: "/otpPref" }, function () {
                    this.setState({ directTo: "/otpPref" });
                    this.setState({ redirect: true });
                });
            }
        } catch (err) {
            if (err.response.status === 404) {
                this.setState({ showVisitorDontExist: "block" });
                this.setState({ showVisitorExist: "none" });
            }
            console.log(`An error has occurred: ${err}`);
        }
    }

    fetchVisitorRegister = async () => {
        // add call to AWS API Gateway to fetch products here
        // then set them in state
        try {
            const params = {
                "identifier": this.state.newVisitor.id
            };
            const res = await axios.post(`${config.api.backendUrl}/exists/`, params, {
                "headers": {
                    "x-api-key": `${config.api.xapikey}`
                }
            });

            if (res.data) {
                this.setState({ showVisitorDontExist: "none" });
                this.setState({ showVisitorExist: "block" });
            }
        } catch (err) {
            if (err.response.status === 404) {
                this.setState({ directTo: "/register" }, function () {
                    this.setState({ directTo: "/register" });
                    this.setState({ redirect: true });
                });
            }
            console.log(`An error has occurred: ${err}`);
        }
    }

    //return <Redirect push to={this.state.directTo} />;

    render() {
        if (this.state.redirect) {
            return <Redirect push to={{
                pathname: this.state.directTo,
                //search: "?utm=your+face",
                state: {
                    id: this.state.newVisitor.id,
                    mobile: this.state.newVisitor.mobile,
                    email: this.state.newVisitor.email
                }
            }} />;
        }
        return (
            <div >
                <Headers></Headers>
                <FormWithConstraints
                    ref={form => this.form = form}
                    onSubmit={this.contactSubmit}
                    noValidate>
                    <div className="text-center">
                        <p>
                            <h3 style={{ margin: '50px 0px 0px 0px' }}>Visitor Login</h3>
                        </p>
                        <br></br>
                        <Container>
                            <Row>
                                <Col></Col>
                                <Col xs={8}>
                                    <div className="form-group" >
                                        <img src={profileICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                        <input
                                            name="search"
                                            id="search"
                                            type="text"
                                            placeholder="ID or Passport Number"
                                            className="form-control+matching-text-style"
                                            required onChange={this.handleChange}
                                            style={{ width: '80%' }}
                                        />
                                        <FieldFeedbacks for="search">
                                            <FieldFeedback when="*" />
                                        </FieldFeedbacks>
                                    </div>
                                    <div style={{ display: this.state.showVisitorDontExist, color: 'red' }}><p>You have either captured the incorrect ID/Passport or have not registered. Please try again or click register</p></div>
                                    <div style={{ display: this.state.showVisitorExist, color: 'red' }}><p>A registration exists for the captured ID/Passport please login</p></div>
                                </Col>
                                <Col></Col>
                            </Row>

                            <Row>
                                <Col></Col>
                                <Col xs={8}>
                                    <button className="btn btn-secondary" style={{ width: "180px" }}>Login</button>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row>
                                <Col></Col>
                                <Col xs={8}>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row>
                                <Col></Col>
                                <Col xs={8}>
                                    <button className="btn"
                                        onClick={this.handleRegister}
                                        style={{ margin: '10px 0px 70px 0px', textDecoration: 'underline', fontWeight: 'bold', color: '#4A4D4E' }} >Register</button>
                                </Col>
                                <Col></Col>
                            </Row>
                        </Container>

                    </div>
                </FormWithConstraints>
                <Footers></Footers>
            </div>
        );
    }
}

export default logonComp;