import React, { Component } from 'react';
import headerImage1 from "../banner01.png"
import vodlogo from "../logo-icon@75x.png";
import "./headerQR.css";
import profilePic from "../profilePic.png";

class HeadersQR extends Component {
    state = {  }
    render() { 
        return (
            <div>
            <div className="QRheader">
                <span>
                    <div id="QRwrapper">
                            <div id="QRfirst">
                            {
                                this.props.image === '' ? (   
                                    <img className="header-img-circular" src={profilePic} />   
                                ) : (
                                    <img className="header-img-circular" src={this.props.image} />    
                                )
                            }
                            </div>
                            <div id="QRsecond" >                  
                                <div><span>Hi</span></div>
                                <div><span><b>{ this.props.name }</b></span></div>
                            </div>
                            <div id="QRthird">
                                <img src={vodlogo} ></img>
                            </div>
                        </div>
                    </span>                    
                <img src={ headerImage1 } className="headerQRImg" />
            </div>
        </div>        
        );
    }
}
 
export default HeadersQR;