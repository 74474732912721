import React, { Component } from 'react';
import Headers from './headerQR';
import { Redirect } from 'react-router';
import Back from "../backBtn.png";
import Question from "../help-circle red.png";
import "./footer.css";
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'


class faq extends Component {
    constructor(props, context) {
		super(props, context);

        const idLogin = this.props.location.state.id;  
        const dashFirstname = this.props.location.state.firstname;
        const dashLastname = this.props.location.state.lastname;        
        const dashImage = this.props.location.state.image;      

        this.state = {            
            redirect: false,
            directTo: "/",  
            showCapture: false,
            screenshot: null,
            showWebcam: "block",          
            id: idLogin,            
            firstname: dashFirstname,            
            lastname: dashLastname,            
            image: dashImage            
          };  
        }

        handleReturn = e => {
            e.preventDefault();
              this.setState({ redirect: true });
          };

          componentDidMount = () => {
            // console.log(this.state.id);
            // console.log(this.state.firstname);
            // console.log(this.state.lastname);
           }
    
   render() { 
    if (this.state.redirect) {
        return <Redirect push to={{
            pathname: "/visitordash",
            state: { id: this.state.id}
          }} />;
    }
        return ( 
            <div >
                <Headers name={this.state.firstname + ` ` + this.state.lastname} image={this.state.image}></Headers>  
                   
                <div style={{ margin: '360px 0px 0px 0px', textAlign: "center" }}>
                    <h3>FAQ</h3>
                </div>
                <div style={{ margin: '0px 0px 60px 0px', textAlign: "left" }}>
                    
                    
                    <Accordion>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0" >
                                <img src={Question} style={{ margin: '0px 10px 0px 0px'  }}></img>Question 1: Is my data secure ?
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                            <Card.Body>Answer: Your data is stored in compliance with The Protection of Personal Information Act. See here https://www.vodacom.co.za/vodacom/terms/privacy-policy  </Card.Body>
                            </Accordion.Collapse>
                        </Card>  
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1" >
                                <img src={Question} style={{ margin: '0px 10px 0px 0px'  }}></img>Question 2: Can I pre-register for a meeting? 
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                            <Card.Body>Answer: Yes, you or a fellow meeting attendee can pre-register using this URL. </Card.Body>
                            </Accordion.Collapse>
                        </Card>       
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2" >
                                <img src={Question} style={{ margin: '0px 10px 0px 0px'  }}></img>Question 3: Do I report to reception if I have pre-registered?
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                            <Card.Body>Answer: Yes, all visitors are required to report to reception. Once you have checked in at reception your host will be notified of your arrival.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="3" >
                                <img src={Question} style={{ margin: '0px 10px 0px 0px'  }}></img>Question 4:	Who do I contact for technical assistance?
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="3">
                            <Card.Body>Answer: Please speak to the Receptionist at the building you are visiting to log a call for technical assistance. </Card.Body>
                            </Accordion.Collapse>
                        </Card>               
                    </Accordion>
                </div>

                <div className="wrapper">                            
                        <a className="center" >
                            <img  src={Back} onClick={this.handleReturn} ></img>
                        </a>                         
                </div>                     
        </div>              
            );
    }
}
 
export default faq;